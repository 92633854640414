import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { CallToAction } from "../components/CTA"
// import Accordion from "../components/accordion/Accordion"

const ProceduresQueryPage = ({ pageContext, location, data }) => {
  // const [isCosmeticAccordionOpen, setIsCosmeticAccordionOpen] = useState(false)
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext
  let allProcedures = data.allProceduresJson.nodes

  // useEffect(() => {
  //   const hash = location.hash
  //   if (hash && hash === "#cosmetic") {
  //     setIsCosmeticAccordionOpen(true)
  //   }
  // }, [location])

  // const cosmeticProcedures = allProcedures.filter(
  //   p => p.category === "cosmetic"
  // )
  // const oralProcedures = allProcedures.filter(p => p.category === "oral")

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: metaTitle,
      description: metaDescription,
      path: location.pathname,
      productontologyRelations: post.productontologyRelations
    }
  }

  return (
    <Layout language={language} className="procedure-query">
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaData={schemaData}
        pathname={location.pathname}
        lang={language}
      />
      <Section colorBack className="procedure-query__hero relative">
        <Columns sideColumnsSize={2}>
          <div className="column px-40--mobile">
            <Text as="h1" text={post.heading} />
            <Text useStringReplace text={post.topBlurb} />
          </div>
        </Columns>
        <div className="procedure-query__hero--image">
          <Image publicId="INLI/DEV/logo-mark-quarter" />
        </div>
      </Section>

      <div id={post.category}></div>
      <Section smallSpacing>
        <Columns sideColumnsSize={3}>
          <div className="column px-40--mobile">
            <div className="procedure-query__cards mt-3 mt-1--mobile">
              {allProcedures.map((oral, idx) => (
                <Link
                  title={`Learn more about ${oral.name.toLowerCase()}`}
                  to={`/${oral.title}`}
                  key={oral.name}
                  className="procedure-query__card">
                  {idx < 3 && (
                    <div
                      className={`${
                        oral.category === "oral" ? "rounded" : ""
                      } ${idx === 1 || idx === 2 ? "mb-2--desktop" : "mb-2"}`}>
                      <Image
                        publicId={oral.thumbnailPublicId}
                        wrapperClassName={`${
                          idx === 1 || idx === 2 ? "is-hidden-touch" : ""
                        }`}
                      />
                    </div>
                  )}
                  <Text as="h2" text={oral.name} />
                  <Text as="p" text={oral.blurb} />
                </Link>
              ))}
            </div>
          </div>
        </Columns>
      </Section>

      {/*<Columns sideColumnsSize={3}>
        <div className="column px-40--mobile" id="cosmetic">
          <div
            style={{
              height: "2px",
              width: "100%",
              backgroundColor: "#AF7C58"
            }}></div>
        </div>
      </Columns>

      <Section>
        <Columns sideColumnsSize={3}>
          <div className="column px-40--mobile">
            <Accordion
              startOpen={isCosmeticAccordionOpen}
              heading="Cosmetic Surgery Procedures">
              <div className="procedure-query__cards mt-3 mt-1--mobile">
                {cosmeticProcedures.map((oral, idx) => (
                  <Link
                    title={`Learn more about ${oral.name.toLowerCase()}`}
                    to={`/${oral.title}`}
                    key={oral.name}
                    className="procedure-query__card">
                    {idx < 3 && (
                      <div
                        className={`rounded ${
                          idx === 1 || idx === 2 ? "mb-2--desktop" : "mb-2"
                        }`}>
                        <Image
                          publicId={oral.thumbnailPublicId}
                          wrapperClassName={`${
                            idx === 1 || idx === 2 ? "is-hidden-touch" : ""
                          }`}
                        />
                      </div>
                    )}
                    <Text as="h2" text={oral.name} />
                    <Text as="p" text={oral.blurb} />
                  </Link>
                ))}
              </div>
            </Accordion>
          </div>
        </Columns>
      </Section> */}

      <CallToAction
        colorBack
        heading={post.callToAction.heading}
        blurb={post.callToAction.blurb}
        buttons={post.callToAction.buttons}
      />
    </Layout>
  )
}

const ProcedureCard = ({
  hasThisPage,
  isFeatured,
  slug,
  image,
  name,
  blurb,
  headingClass
}) => {
  return (
    <div className="procedure-query__card">
      <WithLink
        noFade
        slug={hasThisPage ? slug : false}
        title={`Learn More About ${name}`}>
        {isFeatured && (
          <Image className="rounded hover-scale-up" publicId={image} />
        )}
      </WithLink>

      <WithLink
        slug={hasThisPage ? slug : false}
        title={`Learn More About ${name}`}>
        <Text as="h2" className={headingClass + " px-40--mobile"} text={name} />
      </WithLink>
      <Text className="px-40--mobile" as="p" text={blurb} />
    </div>
  )
}

const WithLink = ({ slug, children, title, noFade }) => {
  if (slug) {
    const styles = {}
    if (noFade) {
      styles.opacity = 1
    }
    return (
      <Link style={styles} to={slug} title={title}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export const pageQuery = graphql`
  query proceduresQueryPageEs(
    $title: String!
    $language: String!
    $category: String!
  ) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        topBlurb
        additionalProceduresHeading
        productontologyRelations
        schemas {
          presets
          custom
        }
        callToAction {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allProceduresJson(
      filter: {
        isHiddenQueryPage: { eq: false }
        language: { eq: $language }
        category: { eq: $category }
      }
      sort: { fields: order }
    ) {
      nodes {
        ...ProcedureQuery
      }
    }
  }
`

export default ProceduresQueryPage
